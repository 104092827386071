import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Component, Inject, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { DocumentoFD } from 'src/app/core/models/documentoFD';
import Swal from 'sweetalert2';
import { Parametricas } from '../../../core/enums/parametricas.enums'
import { VisorPdfDialogoComponent } from 'src/app/core/components/visor-pdf-dialogo/visor-pdf-dialogo.component';
import { AdsibUtilService } from 'src/app/core/services/adsib-util.service';
import { TramiteService } from 'src/app/core/services/tramite.service';
import { lastValueFrom } from "rxjs";
import { UsuarioService } from "src/app/modules/auth/services/usuario.service";
@Component({
  selector: 'app-formulario-rechazo',
  templateUrl: './formulario-rechazo.component.html',
  styleUrls: ['./formulario-rechazo.component.scss']
})
export class FormularioRechazoComponent implements OnInit {
  public rechazoForm: FormGroup;
  idTramite: number
  idEstado: any
  tramite: any
  sustancia: any
  usuario: any
  tituloC = Parametricas.TITULO
  subtituloC = Parametricas.subtitulo
  constructor(private usuarioService: UsuarioService,
    public dialog: MatDialog,
    public dialogrechazo: MatDialogRef<FormularioRechazoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private adsibUtilService: AdsibUtilService,
    private tramiteService: TramiteService,
  ) {
    this.rechazoForm = this.formBuilder.group({
      id_tramite: data.id_tramite,
      motivo_rechazo: ['', Validators.maxLength(2500)]
    })
    this.idTramite = data.id_tramite || data.idTramite
    this.idEstado = data.idEstado

  }

  ngOnInit(): void {
    this.usuario = this.usuarioService.usuario
    this.dialogrechazo.updateSize('700px', 'auto')
    //  console.log("data",this.data)
    this.obtenerDatosTramite()
  }

  async obtenerDatosTramite() {
    try {
      var respDatosTramite = await lastValueFrom(this.tramiteService.obtenerDatosTramite({ idTramite: this.idTramite }))
    } catch (error) {
      Swal.fire({ text: error.error.mensaje, icon: 'warning', confirmButtonText: 'Aceptar' })
      return
    }
    this.tramite = respDatosTramite?.datos.datosTramite
    this.sustancia = respDatosTramite?.datos.datosSustancia
  }

  async rechazarTramite() {
    console.log("this.tramite:::::::", this.tramite);
    if ((this.tramite.id_tipo_tramite == 8) ||
      (this.tramite.id_tipo_tramite == 12) ||
      (this.tramite.id_tipo_tramite == 15) ||
      (this.tramite.id_tipo_tramite == 17)) {
      this.CrearInformeImprocedencia()
    } else {
      this.GuardarFirmarRechazo()
    }
  }

  async GuardarFirmarRechazo() {
    var respuesta = await Swal.fire({
      title: "¿Desea guardar el registro?", text: "¡No podrás revertir esto! ", icon: "question",
      showCancelButton: true, confirmButtonText: "Si, guardar",
      cancelButtonText: "No, cancelar",
      showCloseButton: true
    })
    if (!respuesta.isConfirmed) return
    Swal.fire("", "Espere por favor", "info");
    Swal.fire({
      title: "Espere por favor...",
      html: "¡Almacenando Registro!",
      allowOutsideClick: false,
    });
    Swal.showLoading()
    const params = {
      id_tramite: this.data.idTramite,
      id_estado_rechazado: this.idEstado,/////estado pre-rechazado   
      //d_estado_rechazado:406,/////estado pre-rechazado   
      motivo_rechazo: this.rechazoForm.value.motivo_rechazo
    }

    // console.log("paramsrechazado",params)
    try {
      var resp = await lastValueFrom(this.tramiteService.rechazoTramite(params));
    } catch (error) {
      Swal.fire("Error2", error.error.mensaje, "error");
      return;
    }

    Swal.close();
    //tenemos datos del pdf64
    if (!resp.datos.pdf64) Swal.fire("Error", "No tenemos el archivo para mostrarlo", "info");

    // Visualizar el pdf generado
    /*     const dialogModalPdf = this.dialog.open(VisorPdfDialogoComponent, {
    data: {
      archivoBase64: resp.datos.pdf64,
      titulo: "Documento a ser aprobado",
      subtitulo: "Revise el documento cuidadosamente luego presione aceptar si esta de acuerdo.",
      textoBoton: "Aceptar"
    },
  });
  var result = await lastValueFrom(dialogModalPdf.afterClosed())
  //console.log("ddd",result);

  if (!result) return ;
  if(result.res==false){
    this.cancelar()
        }
  if (!result.res) return; */

    try {
      await this.adsibUtilService.firmarDocumentoDigitalmente(resp.datos.pdf64, resp.datos.idv4)
    } catch (error) {
      // Swal.fire('', error?.error?.mensaje, 'warning');   
      return
    }
    const paramsCrear = {
      id_tramite: this.data.idTramite,
      motivo_rechazo: this.rechazoForm.value.motivo_rechazo
    }
    try {
      await lastValueFrom(this.tramiteService.crearRechazo(paramsCrear));
    } catch (error) {
      Swal.fire("Error", error.error.mensaje, "error");
      return
    }

    this.dialogrechazo.close({ res: true, code: 1 });
    return
  }

  cancelar() {
    this.dialogrechazo.close({ res: false, code: 0 }); // false: cancelar
  }


  async CrearInformeImprocedencia() {
    var respuesta = await Swal.fire({
      title: "¿Desea guardar el registro?", text: "¡No podrás revertir esto! ", icon: "question",
      showCancelButton: true,
      confirmButtonText: "Si, guardar",
      cancelButtonText: "No, cancelar",
      showCloseButton: true
    })

    if (!respuesta.isConfirmed) return
    Swal.fire("", "Espere por favor", "info");
    Swal.fire({
      title: "Espere por favor...",
      html: "Generando PDF",
      allowOutsideClick: false,
    });
    Swal.showLoading()
    //const idTramite =  this.data?.id_tramite || this.data.idtramite
    const dataTramite = this.data

    const params = {
      id_tramite: dataTramite.id_tramite || dataTramite.idTramite,
      observacion: this.rechazoForm.value.motivo_rechazo
    }

    this.dialogrechazo.close({ res: true, code: 1, params: params });
    return
  }
}
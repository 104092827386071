<mat-toolbar>
  <div class="modo-prueba" *ngIf="dgsc">
    <span style="cursor:default">
       ENTORNO DE PRUEBAS DGSC
    </span>
  </div>

  <button mat-icon-button (click)="drawer.toggle()">
    <mat-icon style="color: #34b0a6;">dashboard</mat-icon>
  </button>

  <div class="dashboard-logo">
    <a routerLink="/dashboard">
      <img src="assets/images/iconofinal.png" alt="Sircof dashboard" class="dashboard-logo__image" width="40"
        height="40" />
    </a>

  </div>
  <a routerLink="/dashboard" class="mat-toolbar__username" style="font-size: 1rem; padding: 2rem;
  padding-left: 0.2rem;">
    <strong>ED-6 Servidor Público</strong>
  </a>
  <span class="example-spacer"></span>


  <div class="mat-toolbar__username">
    <strong>{{usuario.nombre}}<br>{{usuario.rol}} ({{usuario.oficina}})</strong>
  </div>

  <app-notificaciones></app-notificaciones>
  <div class="toolbar__initial-background" [matMenuTriggerFor]="perfil">
    <ng-container *ngIf="_usuarioService.foto.length > 0; else defaultAvatar">
      <img mat-card-image [src]="'data:image/jpeg;base64,' + _usuarioService.foto"
        style="width: 45px; border-radius: 100%; margin-top: 0px;">
    </ng-container>
    <ng-template #defaultAvatar>

      <img mat-card-image src="assets/images/icons8-usuario-64.png" style="height:45px; width:45px;  margin-top: 15px;">

    </ng-template>
  </div>

  <mat-menu #perfil="matMenu" class="profile-menu">

    <!--  <div style="text-align: center; margin:0.8em;">
            <img mat-card-image src="assets/svg/account-avatar-profile-user-6-svgrepo-com.svg" alt="" height="25"
                width="25">
            <div style="text-align: center;">
                <span style="font-size: 0.7rem;">
                    {{usuario.nombre}}<br>{{usuario.rol}} ({{usuario.oficina}})</span>
            </div>
        </div>
        <mat-divider></mat-divider> -->
    <!--         <button mat-menu-item routerLink="/home/dashboard">
        <mat-icon aria-hidden="false">account_circle</mat-icon>
        <span>Perfil</span>
        </button> -->
    <button mat-menu-item (click)="cambiarClaveUsuario()">
      <mat-icon aria-hidden="false">settings</mat-icon>
      <span>Cambiar Contraseña</span>
    </button>
    <button mat-menu-item (click)="cambiarRol()">
      <mat-icon aria-hidden="false">assignment_ind</mat-icon>
      <span>Cambiar Rol</span>
    </button>
    <button mat-menu-item (click)="_usuarioService.logout()">
      <mat-icon>power_settings_new</mat-icon>
      <span>Salir</span>
    </button>
  </mat-menu>
</mat-toolbar>

<mat-drawer-container>
  <mat-drawer mode="side" #drawer class="sidenav-container" fixedInViewport
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'" [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="(isHandset$ | async) === false" color="mat-blue">

    <mat-nav-list><br>
      <!--          <div class="row" style="text-align: center; font-size: 0.7rem; ">
                <img  style="text-align: center;" src="assets/svg/account-avatar-profile-user-6-svgrepo-com.svg" alt="" height="40"
                    width="40"> <br>
                <div style="text-align: center;color: #fff; ">
                    <span >
                        {{usuario.nombre}}<br>{{usuario.rol.toUpperCase()}}<br>{{usuario.oficina}}</span>
                </div>
            </div>
           <br>
           <mat-divider></mat-divider>  -->

      <a mat-list-item class="menu__item--parent my-4" routerLink="/dashboard" routerLinkActive="activo">
        <mat-icon aria-hidden="false" class="sidebar__icon">desktop_mac</mat-icon>
        <span routerLink="/dashboard">Inicio</span>
      </a>
      <a mat-list-item class="menu__item--parent my-4" routerLink="/admin/seleccionar-rol" routerLinkActive="activo">
        <mat-icon aria-hidden="false" class="sidebar__icon">assignment_ind</mat-icon>
        <span>Seleccionar Rol</span>
      </a>
      <a mat-list-item class="menu__item--parent my-4" routerLink="/registros/ver-registro">
        <mat-icon aria-hidden="false" class="sidebar__icon">article</mat-icon>
        <span>Ver formularios de registro</span>
      </a>
      <a mat-list-item class="menu__item--parent my-4" routerLink="/registros/ver-certificado">
        <mat-icon aria-hidden="false" class="sidebar__icon">picture_as_pdf</mat-icon>
        <span>Ver Certificado de Registro</span>
    </a>
      <!--             <a mat-list-item class="menu__item--parent my-4" routerLink="/dispositivos/habilitar-dispositivo">
              <mat-icon aria-hidden="false" class="sidebar__icon">phone_iphone</mat-icon>
              <span>Habilitar dispositivo</span>
            </a> -->
      <a mat-list-item class="menu__item--parent my-4" routerLink="/registros/ver-compra-local">
            <mat-icon aria-hidden="false" class="sidebar__icon">article</mat-icon>
            <span>Buscar Compra Local</span>
      </a>

      <a mat-list-item class="menu__item--parent my-4" routerLink="/registros/ver-hoja-ruta">
        <mat-icon aria-hidden="false" class="sidebar__icon">article</mat-icon>
        <span>Buscar Hoja de Ruta</span>
      </a>

      <a mat-list-item class="menu__item--parent my-4" routerLink="/registros/ver-resolucion-administrativa">
        <mat-icon aria-hidden="false" class="sidebar__icon">article</mat-icon>
        <span>Buscar Resolucion Administrativa</span>
      </a>

      <div *ngFor="let menu of menus">
        <a mat-list-item (click)="menu.mostrar = !menu.mostrar" class="menu__item--parent"
          routerLinkActive="menu.mostrar ? 'activo' : null">
          <mat-icon aria-hidden="false" class="sidebar__icon">{{menu.img}}</mat-icon>
          <span style="margin-right: 1rem">{{menu.nombre}}</span>
          <span style="display: inline-flex; position: absolute; right: 0;">
            <mat-icon style="font-size: 20px; height: 20px;">{{ menu.mostrar ? 'expand_more' :
              'chevron_right' }} </mat-icon>
          </span>
        </a>
        <!-- <a mat-list-item (click)="menu.mostrar = !menu.mostrar" class="menu__item--parent" style="border: thin solid; display: flex;justify-content: space-between">
                    
                    <mat-icon aria-hidden="false" class="sidebar__icon">{{menu.img}}</mat-icon>
                    
                    <span [matBadge]="menu.submenu.length" matBadgeOverlap="false" matBadgeSize="small"
                        matBadgeColor="warn" [matBadgeHidden]="menu.mostrar">{{menu.nombre}}</span>
                </a> -->
        <div *ngIf="menu.submenu && menu.mostrar">
          <a mat-list-item *ngFor="let submenu of menu.submenu" class="submenu__item--child" [routerLink]="submenu.ruta"
            routerLinkActive="activo">
            <!-- <mat-icon aria-hidden="false" class="sidebar__icon">{{submenu.img}}</mat-icon> -->
            <span>{{submenu.nombre}}</span>
          </a>
        </div>
      </div>
    </mat-nav-list>

  </mat-drawer>

  <mat-drawer-content>
    <div class="main-container">
      <router-outlet></router-outlet>
    </div>
  </mat-drawer-content>

</mat-drawer-container>